<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "trud-discord-logo",
})
</script>

<template>
  <div class="trud-discord-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <g clip-path="url(#clip0_764_1207)">
        <path d="M22.859 28.1365V28.1172C20.6164 28.1172 18.8066 29.9711 18.8066 32.2497C18.8066 34.5283 20.6164 36.3823 22.859 36.3823C25.1016 36.3823 26.9114 34.5284 26.9114 32.269C26.9114 29.9902 25.0819 28.1365 22.859 28.1365Z" fill="#31E7A7"/>
        <path d="M30 0.703125C13.8198 0.703125 0.703125 13.8198 0.703125 30C0.703125 46.1802 13.8198 59.2969 30 59.2969C46.1802 59.2969 59.2969 46.1802 59.2969 30C59.2969 13.8198 46.1802 0.703125 30 0.703125ZM40.8391 45.4583C40.8391 45.4583 39.3633 43.8164 38.3014 42.3686C43.4161 40.9202 45.3444 38.0237 45.3444 38.0237C40.0266 41.3116 35.06 42.5767 30 42.4123C24.94 42.5763 19.9734 41.3116 14.6562 38.0237C14.6562 38.0237 16.5839 40.9202 21.6991 42.3686C20.637 43.8169 19.1616 45.4583 19.1616 45.4583C10.3874 45.2652 6.98438 39.8968 6.7875 40.1285C6.7875 28.1754 12.5906 18.4425 12.5906 18.4425C17.7839 14.7159 22.6232 14.561 23.5477 14.5417L24.1379 15.1017C17.5085 16.9555 14.302 19.8521 14.302 19.8521C19.9918 17.104 25.3546 16.1373 30 16.0794C34.6455 16.138 40.0078 17.104 45.698 19.8521C45.698 19.8521 42.4915 16.9555 35.8622 15.1017L36.4523 14.5417C37.377 14.561 42.2161 14.7155 47.4094 18.4425C47.4094 18.4425 53.2124 28.1752 53.2124 40.1285C53.0156 39.8968 49.6126 45.2652 40.8391 45.4583Z" fill="#31E7A7"/>
        <path d="M37.1404 28.1172V28.1365C34.9174 28.1365 33.0879 29.9904 33.0879 32.269C33.0879 34.5284 34.8979 36.3823 37.1404 36.3823C39.3829 36.3823 41.1927 34.5284 41.1927 32.2497C41.1927 29.971 39.383 28.1172 37.1404 28.1172Z" fill="#31E7A7"/>
      </g>
      <defs>
        <clipPath id="clip0_764_1207">
          <rect width="60" height="60" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style scoped lang="sass">

</style>