<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "trud-twitter-logo",
})
</script>

<template>
  <div class="trud-twitter-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <path d="M30 0C13.4581 0 0 13.4581 0 30C0 46.5407 13.4581 60 30 60C46.5407 60 60 46.5407 60 30C60 13.4581 46.5431 0 30 0ZM43.3831 23.1338C43.3964 23.4311 43.4037 23.7308 43.4037 24.0305C43.4037 33.1552 36.4602 43.6732 23.7561 43.6732C19.8566 43.6732 16.2265 42.5336 13.1717 40.5736C13.7118 40.6376 14.2617 40.6703 14.8187 40.6703C18.0549 40.6703 21.0312 39.5658 23.3948 37.7145C21.9538 37.6873 20.5573 37.2104 19.4007 36.3505C18.2441 35.4905 17.3853 34.2905 16.9443 32.9183C17.979 33.116 19.045 33.0764 20.062 32.8023C18.4992 32.4865 17.0938 31.6397 16.0844 30.4055C15.0749 29.1714 14.5235 27.626 14.5239 26.0316L14.5251 25.9446C15.4834 26.4773 16.5553 26.7732 17.6513 26.8074C16.7058 26.1766 15.9308 25.322 15.3951 24.3196C14.8594 23.3172 14.5797 22.198 14.5807 21.0614C14.5807 19.795 14.9202 18.6083 15.5148 17.5884C17.2485 19.7218 19.4114 21.4667 21.8632 22.71C24.3149 23.9532 27.0007 24.667 29.7462 24.805C29.6267 24.2888 29.5671 23.7604 29.5686 23.2305C29.5678 21.8453 29.9836 20.4919 30.762 19.3461C31.5404 18.2003 32.6454 17.3151 33.9335 16.8056C35.2216 16.296 36.6331 16.1857 37.9847 16.4888C39.3363 16.792 40.5655 17.4947 41.5125 18.5056C43.0598 18.2019 44.5435 17.635 45.8991 16.8295C45.3822 18.4296 44.3018 19.7875 42.8587 20.6505C44.2277 20.4896 45.5647 20.1234 46.8247 19.5642C45.9009 20.9508 44.7352 22.16 43.3831 23.1338Z" fill="#31E7A7"/>
    </svg>
  </div>
</template>

<style scoped lang="sass">
.trud-twitter-logo
</style>