<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "trud-telegram-logo",
})
</script>

<template>
  <div class="trud-telegram-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <g clip-path="url(#clip0_705_422)">
        <path d="M30 60C46.5725 60 60 46.5725 60 30C60 13.4275 46.5725 0 30 0C13.4275 0 0 13.4275 0 30C0 46.5725 13.4275 60 30 60ZM13.7275 29.35L42.6525 18.1975C43.995 17.7125 45.1675 18.525 44.7325 20.555L44.735 20.5525L39.81 43.755C39.445 45.4 38.4675 45.8 37.1 45.025L29.6 39.4975L25.9825 42.9825C25.5825 43.3825 25.245 43.72 24.47 43.72L25.0025 36.0875L38.9025 23.53C39.5075 22.9975 38.7675 22.6975 37.97 23.2275L20.7925 34.0425L13.3875 31.7325C11.78 31.2225 11.745 30.125 13.7275 29.35Z" fill="#31E7A7"/>
      </g>
      <defs>
        <clipPath id="clip0_705_422">
          <rect width="60" height="60" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style scoped lang="sass">
.trud-telegram-logo
</style>