<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "trud-medium-logo",
})
</script>

<template>
  <div class="trud-medium-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <g clip-path="url(#clip0_764_1218)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM20.0634 42.945C27.1639 42.945 32.92 37.1495 32.92 30.0003C32.92 22.8512 27.1639 17.0557 20.0636 17.0557C12.963 17.0557 7.20703 22.8512 7.20703 30.0003C7.20703 37.1495 12.963 42.945 20.0634 42.945ZM40.5956 42.1855C44.1458 42.1855 47.0238 36.73 47.0238 30.0003C47.0238 23.2706 44.1458 17.8152 40.5956 17.8152C37.0454 17.8152 34.1674 23.2706 34.1674 30.0003C34.1674 36.73 37.0454 42.1855 40.5956 42.1855ZM50.5321 40.9177C51.7807 40.9177 52.793 36.0298 52.793 30.0003C52.793 23.9708 51.7807 19.0829 50.5321 19.0829C49.2833 19.0829 48.2713 23.9708 48.2713 30.0003C48.2713 36.0298 49.2834 40.9177 50.5321 40.9177Z" fill="#31E7A7"/>
      </g>
      <defs>
        <clipPath id="clip0_764_1218">
          <rect width="60" height="60" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style scoped lang="sass">
.trud-medium-logo
</style>