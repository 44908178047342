<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "trud-instagram-logo",
})
</script>

<template>
  <div class="trud-instagram-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <g clip-path="url(#clip0_764_1229)">
        <path d="M30 36.1523C33.3978 36.1523 36.1523 33.3978 36.1523 30C36.1523 26.6022 33.3978 23.8477 30 23.8477C26.6022 23.8477 23.8477 26.6022 23.8477 30C23.8477 33.3978 26.6022 36.1523 30 36.1523Z" fill="#31E7A7"/>
        <path d="M30 0.703125C13.8198 0.703125 0.703125 13.8198 0.703125 30C0.703125 46.1802 13.8198 59.2969 30 59.2969C46.1802 59.2969 59.2969 46.1802 59.2969 30C59.2969 13.8198 46.1802 0.703125 30 0.703125ZM48.1005 37.4461C47.9599 40.2314 47.1772 42.978 45.1474 44.9863C43.0979 47.0136 40.3383 47.769 37.5261 47.9082H22.474C19.6615 47.769 16.9022 47.0141 14.8527 44.9863C12.8228 42.978 12.0402 40.2314 11.8996 37.4461V22.5539C12.0402 19.7686 12.8229 17.022 14.8527 15.0136C16.9022 12.9862 19.6618 12.2309 22.474 12.0918H37.526C40.3385 12.2309 43.0978 12.9859 45.1473 15.0136C47.1772 17.022 47.9598 19.7686 48.1004 22.5539L48.1005 37.4461Z" fill="#31E7A7"/>
        <path d="M37.3354 15.4856C33.6691 15.385 26.3308 15.385 22.6644 15.4856C20.7565 15.5379 18.5936 16.0129 17.2342 17.4649C15.8216 18.9741 15.2878 20.7976 15.2337 22.8374C15.1385 26.4178 15.2337 37.1638 15.2337 37.1638C15.2957 39.2035 15.8216 41.0272 17.2342 42.5364C18.5936 43.9888 20.7565 44.4633 22.6644 44.5157C26.3308 44.6163 33.6691 44.6163 37.3354 44.5157C39.2433 44.4633 41.4062 43.9884 42.7656 42.5364C44.1782 41.0272 44.712 39.2037 44.7661 37.1638V22.8374C44.712 20.7976 44.1782 18.9741 42.7656 17.4649C41.4058 16.0124 39.2428 15.5379 37.3354 15.4856ZM29.9994 39.5368C28.1134 39.5368 26.2697 38.9775 24.7014 37.9296C23.1332 36.8818 21.911 35.3925 21.1892 33.65C20.4674 31.9075 20.2786 29.9901 20.6465 28.1402C21.0145 26.2904 21.9227 24.5912 23.2564 23.2576C24.59 21.9239 26.2892 21.0157 28.139 20.6477C29.9889 20.2798 31.9063 20.4686 33.6488 21.1904C35.3913 21.9122 36.8806 23.1344 37.9284 24.7027C38.9763 26.2709 39.5356 28.1146 39.5356 30.0006C39.5356 32.5298 38.5309 34.9553 36.7425 36.7437C34.9541 38.5321 32.5286 39.5368 29.9994 39.5368ZM39.5761 22.4329C39.1989 22.4328 38.8302 22.3209 38.5166 22.1113C38.2031 21.9016 37.9587 21.6037 37.8144 21.2552C37.6701 20.9067 37.6324 20.5233 37.7061 20.1533C37.7797 19.7834 37.9614 19.4436 38.2281 19.1769C38.4949 18.9102 38.8347 18.7286 39.2047 18.6551C39.5746 18.5815 39.9581 18.6193 40.3066 18.7637C40.655 18.908 40.9529 19.1525 41.1624 19.4661C41.372 19.7798 41.4838 20.1485 41.4838 20.5257C41.4838 20.7762 41.4345 21.0242 41.3386 21.2557C41.2427 21.4871 41.1022 21.6974 40.925 21.8745C40.7479 22.0516 40.5376 22.192 40.3061 22.2879C40.0747 22.3837 39.8266 22.433 39.5761 22.4329Z" fill="#31E7A7"/>
      </g>
      <defs>
        <clipPath id="clip0_764_1229">
          <rect width="60" height="60" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style scoped lang="sass">
.trud-instagram-logo
</style>