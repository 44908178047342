<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "trud-youtube-logo",
})
</script>

<template>
  <div class="trud-youtube-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <g clip-path="url(#clip0_764_1212)">
        <path d="M25.8398 34.8391L34.1609 30.0115L25.8398 25.1602V34.8391Z" fill="#31E7A7"/>
        <path d="M30 0C24.0666 0 18.2664 1.75947 13.3329 5.05591C8.39943 8.35236 4.55426 13.0377 2.28363 18.5195C0.0129985 24.0013 -0.581101 30.0333 0.576455 35.8527C1.73401 41.6721 4.59123 47.0176 8.78681 51.2132C12.9824 55.4088 18.3279 58.266 24.1473 59.4236C29.9667 60.5811 35.9987 59.987 41.4805 57.7164C46.9623 55.4458 51.6477 51.6006 54.9441 46.6671C58.2405 41.7336 60 35.9334 60 30C60 22.0435 56.8393 14.4129 51.2132 8.7868C45.5871 3.16071 37.9565 0 30 0ZM45.7895 35.3092C45.79 36.0938 45.6359 36.8709 45.336 37.596C45.0361 38.321 44.5962 38.9799 44.0416 39.5349C43.487 40.0899 42.8284 40.5301 42.1036 40.8305C41.3787 41.1309 40.6018 41.2855 39.8171 41.2855H20.1829C19.3983 41.2855 18.6213 41.1309 17.8965 40.8305C17.1716 40.5301 16.513 40.0899 15.9584 39.5349C15.4038 38.9799 14.9639 38.321 14.664 37.596C14.3641 36.8709 14.21 36.0938 14.2105 35.3092V24.6908C14.21 23.9062 14.3641 23.1291 14.664 22.4041C14.9639 21.679 15.4038 21.0201 15.9584 20.4651C16.513 19.9101 17.1716 19.4699 17.8965 19.1695C18.6213 18.8691 19.3983 18.7145 20.1829 18.7145H39.8171C40.6018 18.7145 41.3787 18.8691 42.1036 19.1695C42.8284 19.4699 43.487 19.9101 44.0416 20.4651C44.5962 21.0201 45.0361 21.679 45.336 22.4041C45.6359 23.1291 45.79 23.9062 45.7895 24.6908V35.3092Z" fill="#31E7A7"/>
      </g>
      <defs>
        <clipPath id="clip0_764_1212">
          <rect width="60" height="60" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style scoped lang="sass">
.trud-youtube-logo
</style>